import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { Box, AppBar, Toolbar, Typography, IconButton,Button } from '@mui/material';
import logo from '../../asste/lolo.png'
import LogoutIcon from '@mui/icons-material/Logout';
const Navbar = ({ handelToggel,fetchToken }) => {
    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,backgroundColor:"#fff" }}>
            <Toolbar>

                <IconButton
                    edge="start"
                    color="#254336"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handelToggel} // Properly calling the toggle function
                >
                    <MenuIcon />
                  
                </IconButton>
   
                <div style={{width : '100%',display:'flex',
                    justifyContent:'space-between',alignItems:'center'
                }}>
                                <img src={logo} width={100}/>
           <LogoutIcon sx={{color:"#254336",'&:hover': {
                  color: '#34495e', cursor:'pointer'
                },}} onClick={()=>{
                    fetchToken(null)}}/>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar;
