import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
  TablePagination
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useNavigate } from "react-router-dom";

const Vehicles = ({ token }) => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [newVehicle, setNewVehicle] = useState({ reg_no: '', vahicle_name: '' });
  const [users, setUsers] = useState([]);
  const [selectedWorker, setSelectedWorker] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const nav = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0); // Reset to first page
  };

  const fetchVehicles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/vehicles/get/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVehicles(response.data);
      setFilteredVehicles(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/workers/get/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchVehicles();
    fetchUsers();
  }, [token]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setFilteredVehicles(vehicles.filter(vehicle =>
      vehicle.vahicle_name.toLowerCase().includes(query) ||
      vehicle.reg_no.toLowerCase().includes(query)
    ));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewVehicle({ reg_no: '', vahicle_name: '' });
  };

  const handleOpenAssignModal = (vehicleId) => {
    setSelectedVehicleId(vehicleId);
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setSelectedWorker('');
  };

  const handleOpenEditModal = (vehicle) => {
    setNewVehicle({ reg_no: vehicle.reg_no, vahicle_name: vehicle.vahicle_name });
    setSelectedVehicleId(vehicle.id);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setNewVehicle({ reg_no: '', vahicle_name: '' });
  };

  const handleAddVehicle = async () => {

if(!newVehicle.reg_no || !newVehicle.vahicle_name){
  setSnackbarSeverity('error')
  setSnackbarMessage("All fields are required.");
  setSnackbarOpen(true)
  return
}

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}apiv1/vehicle/add`, newVehicle, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage("Vehicle added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      fetchVehicles(); // Refresh vehicle list
      handleCloseModal();
    } catch (error) {
      console.error("Error adding vehicle:", error);
      setSnackbarMessage("Error adding vehicle.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleEditVehicle = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}apiv1/vehicle/update/${selectedVehicleId}`, newVehicle, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage("Vehicle updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      fetchVehicles(); // Refresh vehicle list
      handleCloseEditModal();
    } catch (error) {
      console.error("Error updating vehicle:", error);
      setSnackbarMessage("Error updating vehicle.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteVehicle = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}apiv1/vehicle/delete/${selectedVehicleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage("Vehicle deleted successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      fetchVehicles(); // Refresh vehicle list
      setConfirmDeleteOpen(false);
    } catch (error) {
      console.error("Error deleting vehicle:", error);
      setSnackbarMessage("Error deleting vehicle.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setConfirmDeleteOpen(false);
    }
  };

  const handleRemoveAssigned = async (vehicleId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}apiv1/removed/assigned/${vehicleId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage("Vehicle unassigned successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      fetchVehicles(); // Refresh vehicle list
    } catch (error) {
      console.error("Error unassigning vehicle:", error);
      setSnackbarMessage("Error unassigning vehicle.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleAssignVehicle = async () => {
    if (!selectedWorker) {
      setSnackbarMessage("Please select a worker.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}apiv1/vehicle/assign/${selectedVehicleId}`, {
        assigned_to: selectedWorker,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage("Vehicle assigned successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      fetchVehicles(); // Refresh vehicle list
      handleCloseAssignModal();
    } catch (error) {
      console.error("Error assigning vehicle:", error);
      setSnackbarMessage("Driver is already assigned to another vehicle");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleChange = (e) => {
    setNewVehicle({ ...newVehicle, [e.target.name]: e.target.value });
  };

  const sorted = filteredVehicles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  let showdata= sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <Box p={2} sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Vehicles
      </Typography>
      <TextField
        label="Search Vehicles"
        variant="outlined"
        fullWidth
        onChange={handleSearch}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ background: "#0079ae", mb: 2 }}
        onClick={handleOpenModal}
      >
        Add Vehicle
      </Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Registration No</TableCell>
              <TableCell>Vehicle Name</TableCell>
              <TableCell>Assigned To</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              showdata.map((vehicle) => (
                <TableRow key={vehicle.id}>
                  <TableCell>{vehicle.reg_no}</TableCell>
                  <TableCell>{vehicle.vahicle_name}</TableCell>
                  <TableCell>
                    {vehicle.assigned_to ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span>{users.find(user => user.id === vehicle.assigned_to)?.username || "Unknown"}</span>
                        <IconButton onClick={() => handleRemoveAssigned(vehicle.id)} color="secondary" size="small">
                          <RemoveCircleIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Button
                        onClick={() => handleOpenAssignModal(vehicle.id)}
                        variant="outlined"
                        color="primary"
                      >
                        Assign
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenEditModal(vehicle)} color="primary" size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => { setSelectedVehicleId(vehicle.id); setConfirmDeleteOpen(true); }} color="error" size="small">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredVehicles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
            borderTop: '1px solid #e0e0e0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            '& .MuiTablePagination-toolbar': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .MuiButtonBase-root': {
                borderRadius: '4px',
                color: '#0079ae',
                '&:hover': {
                    backgroundColor: '#e0f7fa',
                },
            },
            '& .MuiInputBase-input': {
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                '&:focus': {
                    borderColor: '#0079ae',
                },
            },
        }}
    />

      {/* Modal for Adding Vehicle */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          <Typography variant="h6">Add New Vehicle</Typography>
          <TextField
            label="Registration No"
            name="reg_no"
            value={newVehicle.reg_no}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Vehicle Name"
            name="vahicle_name"
            value={newVehicle.vahicle_name}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            onClick={handleAddVehicle}
            variant="contained"
            color="primary"
          >
            Add Vehicle
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Editing Vehicle */}
      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogContent>
          <Typography variant="h6">Edit Vehicle</Typography>
          <TextField
            label="Registration No"
            name="reg_no"
            value={newVehicle.reg_no}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Vehicle Name"
            name="vahicle_name"
            value={newVehicle.vahicle_name}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>Cancel</Button>
          <Button onClick={handleEditVehicle} variant="contained" color="primary">
            Update Vehicle
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for Deleting Vehicle */}
      <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
        <DialogContent>
          <Typography variant="h6">Confirm Deletion</Typography>
          <Typography>Are you sure you want to delete this vehicle?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteVehicle} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Assigning Vehicle */}
      <Modal
        open={openAssignModal}
        onClose={handleCloseAssignModal}
        aria-labelledby="assign-job-modal"
        aria-describedby="assign-job-description"
      >
        <Box sx={{ p: 4, bgcolor: 'background.paper', width: '90%', maxWidth: '400px', margin: 'auto', marginTop: '15%' }}>
          <Typography variant="h6" gutterBottom>Assign Vehicle</Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="worker-label">Worker</InputLabel>
            <Select
              labelId="worker-label"
              value={selectedWorker}
              onChange={(e) => setSelectedWorker(e.target.value)}
              label="Worker"
            >
              {users.map(worker => (
                <MenuItem key={worker.id} value={worker.id}>
                  {worker.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleAssignVehicle}>
            Submit
          </Button>
        </Box>
      </Modal>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Vehicles;
