
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix the default marker icon issue
let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41]  // size of the shadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const LocationMarker = ({ onLocationSelect, selectedLocation }) => {
  const [position, setPosition] = useState(null);
  const [locationName, setLocationName] = useState('');

  // Fetch reverse geocode location name
  const reverseGeocode = async (lat, lng) => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
        params: { lat, lon: lng, format: 'json', addressdetails: 1 },
      });
      const name = response.data.display_name;
      setLocationName(name);
      onLocationSelect(lat, lng, name);
    } catch (error) {
      console.error('Error fetching location name:', error);
    }
  };

  // Set marker position on map click
  useMapEvents({
    click(event) {
      const { lat, lng } = event.latlng;
      setPosition([lat, lng]);
      reverseGeocode(lat, lng);
    },
  });

  useEffect(() => {
    if (selectedLocation) {
      setPosition([selectedLocation.lat, selectedLocation.lng]);
      setLocationName(selectedLocation.location);
    }
  }, [selectedLocation]);

  return position ? (
    <Marker position={position}>
      <Popup>
        {locationName} <br />
        Lat: {position[0]}, Lng: {position[1]}
      </Popup>
    </Marker>
  ) : null;
};

const LeafletMap = ({ onLocationSelect, selectedLocation }) => {
  const defaultPosition = [53.4129, -8.2439]; // Default to Kolkata

  return (
    <MapContainer
      center={selectedLocation ? [selectedLocation.lat, selectedLocation.lng] : defaultPosition}
      zoom={10}
      style={{ height: '400px', width: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker onLocationSelect={onLocationSelect} selectedLocation={selectedLocation} />
    </MapContainer>
  );
};

export default LeafletMap;
