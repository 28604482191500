import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Paper,
  Dialog,
  DialogContent,
  Snackbar,
  Alert,
  IconButton,
  TablePagination
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';

const Support = ({ token }) => {
  const [supports, setSupports] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(true);
  const [messageBox, setMessageBox] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };
  const fetchSupportList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/support/get/list`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSupports(response.data.support);
      setLoading(false);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage("Cannot find any support message");
      setSnackbarSeverity("error");
      console.log(error);
    }
  };

  const Handle_Remove_message = async (id)=>{
    try{
        const response =  await axios.delete(`${process.env.REACT_APP_API_URL}apiv1/support/remove/${id}`,
            { headers :  {Authorization : `Bearer ${token}`}}
        )

        setSnackbarOpen(true);
        setSnackbarMessage("Messsage is Remove ");
       fetchSupportList()
    }

    
    catch(error){
setSnackbarOpen(true);
setSnackbarMessage("Can not Remove Message")
setSnackbarSeverity("error")
    }
  }

  useEffect(() => {
    fetchSupportList();
  }, [token]);

  const showMessage = (value) => {
    setOpen(true);
    setMessageBox({
      email: value.email,
      phone: value.phone,
      name: value.name,
      message: value.message
    });
  };
  const sorted = supports.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  let showdata= sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <Box p={2} sx={{ flexGrow: 1 }}>
      <Typography variant="h4">Support</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>View Message</TableCell>
              <TableCell>Remove </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              showdata.map((support) => (
                <TableRow key={support.id}>
                  <TableCell>{support.email}</TableCell>
                  <TableCell>{support.name}</TableCell>
                  <TableCell>{support.phone}</TableCell>
                  <TableCell>
                    <Button onClick={() => showMessage(support)}>
                      View Message
                    </Button>
                  </TableCell>
                  <TableCell><DeleteIcon onClick={()=>{
                    Handle_Remove_message(support.id)
                  }}  sx={{color:'red'}}/></TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={supports.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
            borderTop: '1px solid #e0e0e0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            '& .MuiTablePagination-toolbar': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .MuiButtonBase-root': {
                borderRadius: '4px',
                color: '#0079ae',
                '&:hover': {
                    backgroundColor: '#e0f7fa',
                },
            },
            '& .MuiInputBase-input': {
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                '&:focus': {
                    borderColor: '#0079ae',
                },
            },
        }}
    />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
    <DialogContent sx={{ position: 'relative', p: 4 }}>
        <Typography variant="h6">Message from: {messageBox.name}</Typography>
        <Typography variant="subtitle1">Email: {messageBox.email}</Typography>
        <Typography variant="subtitle1">Phone: {messageBox.phone}</Typography>
        <Typography 
            variant="body1" 
            sx={{ 
                mt: 2, 
                border: '1px solid #ccc', 
                p: 2, 
                borderRadius: '4px',
                maxHeight: '200px', // Set a max height for the message box
                overflowY: 'auto', // Allow vertical scrolling if content is too large
                whiteSpace: 'pre-wrap', // Preserve whitespace and wrap text
                wordWrap: 'break-word', // Break long words
            }} 
        >
            {messageBox.message}
        </Typography>
        <Box sx={{ position: "absolute", bottom: '0px', right: '10px', marginTop: '10px' }}>
            <Typography onClick={() => window.location.href = `mailto:${messageBox.email}`} variant="body2" sx={{ display: 'inline', m: 1 }}>
                Send Message:
            </Typography>
            <IconButton>
                <EmailIcon />
            </IconButton>
        </Box>
    </DialogContent>
</Dialog>

    </Box>
  );
};

export default Support;
