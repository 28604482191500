import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Select,
  MenuItem,
  IconButton,
  TablePagination,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import jsPDF from 'jspdf'; // Import jsPDF library
import 'jspdf-autotable'; 
import pdflogo from '../asste/imgpsh_fullsize_anim.png'; // Path to your logo
import * as XLSX from "xlsx"; // Import XLSX library


const JobHistory = ({ token }) => {
  const [feedback, setFeedback] = useState([]);
  const [filter, setFilter] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(""); 
  const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);
  const [downloadJobs, setDownloadJobs] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const nav = useNavigate();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const generateXLSX = (filteredJobs) => {
    // Define columns and their headers
    const data = filteredJobs.map((job) => ({
      Date: new Date(job.createdAt).toLocaleDateString(),
      "Client Name": job.customer_name,
      Address: job.customer_address || "N/A",
      "Weight Docket No": job.weight_docket_no,
      Weight: job.total_tonnes_kg || "N/A",
    }));

    // Create a worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Job Report");

    // Write the workbook and download it as an XLSX file
    XLSX.writeFile(workbook, "filtered-jobs-list.xlsx");
  };

  const fetchFeedbacks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/feedbacks`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFeedback(response.data);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching feedbacks:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedbacks();
  }, [token]); 

  const handleFilterChange = (filterOption) => {
    setFilter(filterOption);
  };

  const filterDataByDate = (data) => {
    const today = new Date();
    return data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      switch (filter) {
        case "today":
          return itemDate.toDateString() === today.toDateString();
        case "7days":
          return (today - itemDate) / (1000 * 3600 * 24) <= 7;
        case "1month":
          return itemDate.getMonth() === today.getMonth() && itemDate.getFullYear() === today.getFullYear();
        case "last_month":
          const lastMonth = new Date(today.setMonth(today.getMonth() - 1));
          return itemDate.getMonth() === lastMonth.getMonth() && itemDate.getFullYear() === lastMonth.getFullYear();
        case "this_year":
          return itemDate.getFullYear() === today.getFullYear();
        default:
          return true; 
      }
    });
  };

  const filteredFeedbacks = filterDataByDate(feedback);
  
  const searchedFeedbacks = filteredFeedbacks.filter((item) =>
    item.weight_docket_no.toString().includes(search) 
  );


  const generatePDF = (filteredJobs) => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const imgWidth = 50; // Desired width of the logo
    const imgHeight = 20;
    const pageWidth = doc.internal.pageSize.getWidth();
    const x = (pageWidth - imgWidth) / 2; 
    // Add logo
    const imgData = pdflogo; // Logo path
    doc.addImage(imgData, 'PNG', x, 10, imgWidth, imgHeight); // Adjust positioning and size as necessary

    // Add the main title of the report
    doc.text('Job Report List', 14, 40, { align: 'left' });

    // Format the date range to be included in the header
    const formattedStartDate = new Date(fromDate).toLocaleDateString();
    const formattedEndDate = new Date(toDate).toLocaleDateString();
    const dateRangeText = `From: ${formattedStartDate} To: ${formattedEndDate}`;

    // // Add the date range below the title
    doc.text(dateRangeText,pageWidth - 14, 40, { align: 'right' });

    // Define the table header with new column names
    const headers = ['Date', 'Client Name', 'Address', 'Weight Docket No',"Weight"];

    // Prepare the table data from filtered jobs
    const data = filteredJobs.map((job) => [
      new Date(job.createdAt).toLocaleDateString(),
      job.customer_name,
      job.customer_address || 'N/A', // Ensure 'address' is included or provide a fallback
      job.weight_docket_no,
      job.total_tonnes_kg || 'N/A',
      // job.ltrbin240 || 'N/A',
      // job.bag || 'N/A',
      // job.box || 'N/A',
      // job.console || 'N/A',
      // job.it_equipment || 'N/A',
    ]);

    // Generate the table using autoTable
    doc.autoTable({
        head: [headers],
        body: data,
        startY: 55, // Start position of the table (below the date range)
    });

    // Save the generated PDF
    doc.save('filtered-jobs-list.pdf');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenDateRangeModal = () => {
    setFromDate(null); 
    setToDate(null);
    setDateRangeModalOpen(true);
  };

  const handleCloseDateRangeModal = () => {
    setDateRangeModalOpen(false);
  };

  const handleDateRangeSubmit = () => {
    try {
      const startDate = new Date(fromDate); 
      const endDate = new Date(toDate);
      startDate.setHours(0, 0, 0, 0); 
      endDate.setHours(23, 59, 59, 999); 

      const filteredJobs = feedback.filter((job) => {
        const jobDate = new Date(job.createdAt);
        return jobDate >= startDate && jobDate <= endDate; 
      });

      if (filteredJobs.length === 0) {
        // Handle no results
      }

      setDownloadJobs(filteredJobs);
      generatePDF(filteredJobs); // Generates PDF
      generateXLSX(filteredJobs); // Generates XLSX

      handleCloseDateRangeModal();
    } catch (error) {
      // Handle errors
    }
  };

  const Feedbacks = searchedFeedbacks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <div>
      <Container maxWidth="lg" className="dashboard-container" sx={{ padding: 2 }}>
        <div style={{
          display:"flex",
          flexDirection : 'row',
          justifyContent:'space-between',
        }}>
        <Select
          sx={{
            background: "#fff",
            fontFamily: "Inter Tight",
            marginBottom: 2,
            minWidth: 120,
          }}
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="7days">Last 7 Days</MenuItem>
          <MenuItem value="1month">This Month</MenuItem>
          <MenuItem value="last_month">Last Month</MenuItem>
          <MenuItem value="this_year">This Year</MenuItem>
        </Select>
        {/* <Button variant="contained" onClick={handleOpenDateRangeModal} sx={{ mb: 2, background: '#0079ae', ml: 2 }} >
          Download Feedback Report PDF
        </Button> */}
        <Button 
            variant="contained" 
            onClick={() => generateXLSX(searchedFeedbacks)} 
            sx={{ mb: 2, background: '#0079ae', ml: 2 }}
          >
            Download Feedback Report
          </Button>
        </div>

        <TextField
          label="Search Weight Docket No"
          variant="outlined"
          fullWidth
          value={search}
          onChange={handleSearchChange} 
        />

        <Typography variant="h6" sx={{ fontFamily: "Inter Tight", marginBottom: 2 }}>
          Feedback List
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontFamily: "Inter Tight" }}>Date</TableCell>
                <TableCell sx={{ fontFamily: "Inter Tight" }}>Customer Name</TableCell>
            
                <TableCell sx={{ fontFamily: "Inter Tight" }}>Date of Destruction</TableCell>
                <TableCell sx={{ fontFamily: "Inter Tight" }}>Weight Docket No</TableCell>
                <TableCell sx={{ fontFamily: "Inter Tight" }}>Total Tonnes (kg)</TableCell>
            
                <TableCell sx={{ fontFamily: "Inter Tight" }}>View Feedback</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Feedbacks.map((item) => (
                <TableRow key={item.id}>
                  <TableCell sx={{ fontFamily: "Inter Tight" }}>
                    {new Date(item.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Inter Tight" }}>{item.customer_name}</TableCell>
                  {/* <TableCell sx={{ fontFamily: "Inter Tight" }}>{item.origin_of_waste}</TableCell> */}
                  {/* <TableCell>{item.ref_no}</TableCell> */}
                  <TableCell sx={{ fontFamily: "Inter Tight" }}>
                    {new Date(item.date_of_destruction).toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Inter Tight" }}>{item.weight_docket_no}</TableCell>
                  <TableCell sx={{ fontFamily: "Inter Tight" }}>{item.total_tonnes_kg}</TableCell>
                  {/* <TableCell sx={{ fontFamily: "Inter Tight" }}>
                    {item.details_of_materials_collected}
                  </TableCell> */}
                  <TableCell>
                    <IconButton onClick={() => nav('/feedback', { state: { JobId: item.job_id } })}>
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={searchedFeedbacks.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Container>

      <Dialog open={dateRangeModalOpen} onClose={handleCloseDateRangeModal}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
            <TextField
                label="From"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                sx={{ mt: 2 }}
            />

            <TextField
                label="To"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                sx={{ mt: 2 }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDateRangeModal} color="primary">
                Cancel
            </Button>
            <Button onClick={handleDateRangeSubmit} color="primary">
                OK
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobHistory;
