import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Grid, TablePagination,Dialog,DialogTitle,DialogContent,TextField,DialogActions,Snackbar,Alert } from '@mui/material';
import axios from 'axios';
import JobTable from '../Component/Job_Modal/JobTable';
import JobFilters from '../Component/Job_Modal/JobFilters';
import AssignJobModal from '../Component/Job_Modal/AssignJobModal';
import JobModal from '../Component/Job_Modal/Job_modal';
import EditJobModal from '../Component/Job_Modal/EditJobModal';
import DeleteJobModal from '../Component/Job_Modal/DeleteJobModal';
import { useLocation, useNavigate } from 'react-router-dom';
import EditAssignJobModal from '../Component/Job_Modal/EditAssgnJobModal';
import jsPDF from 'jspdf'; // Import jsPDF library
import 'jspdf-autotable'; 


const Job = ({ token }) => {
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('all');
    const [jobstatusFilter, setJobstatusFilter] = useState('all');
    const [open, setOpen] = useState(false);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [assignModalOpenEdite, setAssignModalOpenEdit] = useState(false);
    const [workers, setWorkers] = useState([]);
    const [selectedWorker, setSelectedWorker] = useState({
        id: 0,
        fcmToken: '',
        username: '',
    });
    const [assignedDate, setAssignedDate] = useState('');
    const [jobToAssign, setJobToAssign] = useState(null);
    const [jobAssignments, setJobAssignments] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);
    const [downloadJobs, setDownloadJobs] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const location = useLocation();
    const nav = useNavigate();

    const { Processing } = location.state || {};
    const { Pending } = location.state || {};
    const { DataStatus } = location.state || {};
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const fetchJobs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/get/job`, {
                headers: { Authorization: `Bearer ${token}` },
            });
          
            setJobs(response.data);
            setFilteredJobs(response.data);
            setDownloadJobs(response.data)
        } catch (error) {
            // console.error('Error fetching jobs:', error);
        }
    };

    const fetchWorkers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/workers/get/all`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setWorkers(response.data);
        } catch (error) {
            // console.error('Error fetching workers:', error);
        }
    };

    const fetchJobAssignments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/job-assigned`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setJobAssignments(response.data);
        } catch (error) {
            // console.error('Error fetching job assignments:', error);
        }
    };

    useEffect(() => {
        if (Processing) {
            setJobstatusFilter(Processing);
        }
        if (Pending) {
            setJobstatusFilter(Pending);
        }
        if (DataStatus) {
            setFilter(DataStatus);
        }
        fetchJobs();
        fetchWorkers();
        fetchJobAssignments();
    }, []);

    useEffect(() => {
        fetchJobs();
        fetchWorkers();
        fetchJobAssignments();
    }, [deleteModalOpen]);

    useEffect(() => {
        applyFilter();
    }, [filter, search, jobstatusFilter, jobs]);


  

  

    const handleAssignOpen = (job) => {
        if (job.assignedstatus) return;
        setJobToAssign(job);
        setAssignModalOpen(true);
    };

    const handleAssignOpenEdit = (job) => {
        setJobToAssign(job);
        setAssignModalOpenEdit(true);
    };

    const handleAssignClose = () => {
        setAssignModalOpen(false);
        setSelectedWorker('');
        setAssignedDate('');
        setAssignModalOpenEdit(false);
    };

    const handleJobAdded = () => {
        fetchJobs();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };

    const sortedJobs = filteredJobs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Function to generate PDF


    const handleOpenDateRangeModal = () => {
        setFromDate(null); // Reset dates when opening the modal
        setToDate(null);
        setDateRangeModalOpen(true);
    };
    
    const handleCloseDateRangeModal = () => {
        setDateRangeModalOpen(false);
    };
    
    const handleDateRangeSubmit = () => {
        try {
            // Ensure the fromDate and toDate are parsed correctly into Date objects
            const startDate = new Date(fromDate); 
            const endDate = new Date(toDate);
    
            // Set the time to 00:00:00 to compare only the date (ignoring the time part)
            startDate.setHours(0, 0, 0, 0); // Start of the 'from' day
            endDate.setHours(23, 59, 59, 999); // End of the 'to' day
    
            // Filter jobs based on the 'createdAt' date, comparing without time
            const filteredJobs = jobs.filter((job) => {
                const jobDate = new Date(job.createdAt); // Parse the job's 'createdAt' to a Date object
                return jobDate >= startDate && jobDate <= endDate; // Compare the dates
            });
    
            if (filteredJobs.length === 0) {
                console.log('No jobs found within the selected date range.');
            }
    
            // Update the download jobs state with filtered jobs
            setDownloadJobs(filteredJobs);
    
            // Generate the PDF with filtered jobs
            generatePDF(filteredJobs);
    
            // Close the modal
            handleCloseDateRangeModal();
        } catch (error) {
            console.error("Error filtering jobs by date range:", error);
        }
    };
    




  
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

  
  
  
  

  // Updated PDF generation logic
  const generatePDF = (filteredJobs ) => {
    const doc = new jsPDF();
    doc.setFontSize(12);

    // Add the main title of the report
    doc.text('Job Report List', 14, 10);

    // Format the date range to be included in the header
    const formattedStartDate = new Date(fromDate).toLocaleDateString();
    const formattedEndDate = new Date(toDate).toLocaleDateString();
    const dateRangeText = `From: ${formattedStartDate} To: ${formattedEndDate}`;

    // Add the date range below the title
    doc.text(dateRangeText, 14, 15);

    // Define the table header
    const headers = ['Job Title', 'Created At', 'Status', 'Assigned Worker'];

    // Prepare the table data from filtered jobs
    const data = filteredJobs.map((job) => [
        job.job_title,
        new Date(job.createdAt).toLocaleDateString(), // Format the createdAt date
        job.jobstatus ? 'Complete' : 'Pending',
        job.assignedstatus
            ? jobAssignments.find((assignment) => assignment.job_id === job.id)?.worker.username || 'Unknown'
            : 'Not Assigned',
    ]);

    // Generate the table using autoTable
    doc.autoTable({
        head: [headers], // Table headers
        body: data, // Table body
        startY: 25, // Start position of the table (below the date range)
    });

    // Save the generated PDF
    doc.save('filtered-jobs-list.pdf');
};




  
// Main applyFilter function
const applyFilter = () => {
    let filtered = jobs;

    // Filter by search term
    if (search) {
        filtered = filtered.filter((job) =>
            job.client_name.toLowerCase().includes(search.toLowerCase())
        );
    }

    // Apply date range filter
 

    // Apply other time range filters (e.g., 7 days, 1 month, etc.)
    if (filter !== 'all') {
        const now = new Date();
        const filterDate = new Date();

        switch (filter) {
            case '7days':
                filterDate.setDate(now.getDate() - 7);
                break;
            case '1month':
                filterDate.setMonth(now.getMonth() - 1);
                break;
            case '1year':
                filterDate.setFullYear(now.getFullYear(), 0, 1); // Start of the year
                break;
            case 'today':
                filterDate.setHours(0, 0, 0, 0);
                break;
            case 'lastyear':
                filterDate.setFullYear(now.getFullYear() - 1, 0, 1); // Start of last year
                const endLastYear = new Date(now.getFullYear() - 1, 11, 31); // End of last year
                filtered = filtered.filter((job) => {
                    const jobDate = new Date(job.createdAt);
                    return jobDate >= filterDate && jobDate <= endLastYear;
                });
                setFilteredJobs(filtered);
                return; // Exit early for 'lastyear' filter
            default:
                break;
        }

        filtered = filtered.filter((job) => new Date(job.createdAt) >= filterDate);
    }

    // Filter by job status (e.g., 'Pending', 'Complete', etc.)
    if (jobstatusFilter !== 'all') {
        filtered = filtered.filter((job) => {
            const status = job.assignedstatus
                ? job.jobstatus
                    ? 'Complete'
                    : 'Processing'
                : job.jobstatus
                ? 'Complete'
                : 'Pending';
            return status === jobstatusFilter;
        });
    }

    setFilteredJobs(filtered);
};

    
    // Updated PDF generation logic

    
    

    return (
        <Container maxWidth="lg" sx={{ backgroundColor: '#F5F5F7', padding: '16px' }}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() => nav('/create/new/job')}
                            sx={{ mb: 2, background: '#0079ae' }}
                        >
                            Create Job
                        </Button>

                        {/* Download Job Button */}
                        {/* <Button
                            variant="contained"
                            onClick={handleOpenDateRangeModal}
                            sx={{ mb: 2, background: '#0079ae', ml: 2 }}
                        >
                            Download Job
                        </Button> */}
                    </Grid>

                    <Grid item xs={12}>
                        <JobFilters
                            search={search}
                            filter={filter}
                            jobstatusFilter={jobstatusFilter}
                            handleSearchChange={(e) => setSearch(e.target.value)}
                            handleFilterChange={(e) => setFilter(e.target.value)}
                            handleJobstatusFilterChange={(e) => setJobstatusFilter(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <JobTable
                            filteredJobs={sortedJobs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            jobAssignments={jobAssignments}
                            handleAssignOpen={handleAssignOpen}
                            handleAssignOpenEdit={handleAssignOpenEdit}
                            handleEditOpen={(job) => {
                                setSelectedJob(job);
                                setEditModalOpen(true);
                            }}
                            handleDeleteOpen={(jobId) => {
                                setSelectedJob({ id: jobId });
                                setDeleteModalOpen(true);
                            }}
                        />

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredJobs.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                borderTop: '1px solid #e0e0e0',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '16px',
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Modals for Assign Job, Edit Job, Delete Job */}
                <AssignJobModal
                    open={assignModalOpen}
                    handleClose={handleAssignClose}
                    workers={workers}
                    selectedWorker={selectedWorker}
                    setSelectedWorker={setSelectedWorker}
                    assignedDate={assignedDate}
                    setAssignedDate={setAssignedDate}
                    token={token}
                    jobToAssign={jobToAssign}
                    handleAssignSubmit={async () => {
                        if (!jobToAssign || !selectedWorker || !assignedDate) {
                            setOpenSnackbar(true)
                            setSnackbarMessage('All fields are required');
                            setSnackbarSeverity('error');
                            return;
                        }
                        try {
                            await axios.post(
                                `${process.env.REACT_APP_API_URL}apiv1/job-assigned`,
                                {
                                    job_id: jobToAssign.id,
                                    worker_id: selectedWorker.id,
                                    assigned_date: assignedDate,
                                    fcmToken: selectedWorker.fcmToken,
                                },
                                { headers: { Authorization: `Bearer ${token}` } }
                            );
                            fetchJobs();
                            fetchJobAssignments();
                            handleAssignClose();
                            setOpenSnackbar(true)
                            setSnackbarMessage('assigned work completed!');
                            setSnackbarSeverity('success');
                        } catch (error) {
                            setOpenSnackbar(true)
                            setSnackbarMessage(error.response.data.message);
                            setSnackbarSeverity('error');
                        
                            // console.error('Error assigning job:', error);
                        }
                    }}
                />

                <EditAssignJobModal
                    open={assignModalOpenEdite}
                    handleClose={handleAssignClose}
                    workers={workers}
                    selectedWorker={selectedWorker}
                    setSelectedWorker={setSelectedWorker}
                    assignedDate={assignedDate}
                    setAssignedDate={setAssignedDate}
                    token={token}
                    jobToAssign={jobToAssign}
                    handleAssignSubmit={async () => {
                        if (!jobToAssign || !selectedWorker || !assignedDate) {
                            setOpenSnackbar(true)
                            setSnackbarMessage('All fields are required');
                            setSnackbarSeverity('error');
                         
                            return;
                        }
                        try {
                            await axios.put(
                                `${process.env.REACT_APP_API_URL}apiv1/job-assigned/update`,
                                {
                                    job_id: jobToAssign.id,
                                    worker_id: selectedWorker.id,
                                    assigned_date: assignedDate,
                                    fcmToken: selectedWorker.fcmToken,
                                },
                                { headers: { Authorization: `Bearer ${token}` } }
                            );
                            fetchJobs();
                            fetchJobAssignments();
                            handleAssignClose();
                            setOpenSnackbar(true)
                            setSnackbarMessage('assigned work completed!');
                            setSnackbarSeverity('success');
                        } catch (error) {
                            setOpenSnackbar(true)
                            setSnackbarMessage(error.response.data.message);
                            setSnackbarSeverity('error');
                        }
                    }}
                />

                <EditJobModal
                    open={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    job={selectedJob}
                    onJobUpdated={fetchJobs}
                    token={token}
                />

                <DeleteJobModal
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    jobId={selectedJob ? selectedJob.id : null}
                    onJobDeleted={async () => {
                        try {
                            await axios.delete(`${process.env.REACT_APP_API_URL}apiv1/job/${selectedJob.id}`, {
                                headers: { Authorization: `Bearer ${token}` },
                            });
                            fetchJobs();
                            setDeleteModalOpen(false);
                        } catch (error) {
                            // console.error('Error deleting job:', error);
                        }
                    }}
                    token={token}
                />
            </Box>
            <Dialog open={dateRangeModalOpen} onClose={handleCloseDateRangeModal}>
    <DialogTitle>Select Date Range</DialogTitle>
    <DialogContent>
        {/* "From" Date Picker */}
        <TextField
            label="From"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
            sx={{ mt: 2 }}
        />

        {/* "To" Date Picker */}
        <TextField
            label="To"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
            sx={{ mt: 2 }}
        />
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseDateRangeModal} color="primary">
            Cancel
        </Button>
        <Button onClick={handleDateRangeSubmit} color="primary">
            OK
        </Button>
    </DialogActions>
</Dialog>
<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Container>
    );
};

export default Job;




