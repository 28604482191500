import React from 'react';
import {
    Box, TextField, FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';

const JobFilters = ({
    search,
    filter,
    jobstatusFilter,
    handleSearchChange,
    handleFilterChange,
    handleJobstatusFilterChange
}) => {
    return (
        <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Search Clients"
                        variant="outlined"
                        value={search}
                        onChange={handleSearchChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-label">Date Filter</InputLabel>
                        <Select
                            labelId="filter-label"
                            value={filter}
                            onChange={handleFilterChange}
                            label="Date Filter"
                        >
                            <MenuItem value="all">All Time</MenuItem>
                            <MenuItem value="7days">Last 7 Days</MenuItem>
                            <MenuItem value="today">Today</MenuItem>
                            <MenuItem value="1month">Last 1 Month</MenuItem>
                            <MenuItem value="1year">This Year</MenuItem>
                            <MenuItem value="lastyear">Last Year</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel id="job-status-filter-label">Job Status</InputLabel>
                        <Select
                            labelId="job-status-filter-label"
                            value={jobstatusFilter}
                            onChange={handleJobstatusFilterChange}
                            label="Job Status"
                        >
                            <MenuItem value="all">All Status</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Processing">Processing</MenuItem>
                            <MenuItem value="Complete">Complete</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default JobFilters;
