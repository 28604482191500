

// import React, { useEffect } from 'react';
// import {
//     Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, IconButton, Typography
// } from '@mui/material';
// import { Assignment, Edit, Visibility } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';
// import './table.css';

// const JobTable = ({ filteredJobs, handleAssignOpenEdit, jobAssignments, handleAssignOpen, handleEditOpen }) => {
//     const nav = useNavigate();

//     // Helper function to check if the assigned date is past
//     const isDatePast = (assignedDate) => {
//         const currentDate = new Date();
//    // Add 1 hour to the current date
//         const assignedDateObj = new Date(assignedDate);
//         assignedDateObj.setHours(assignedDateObj.getHours() + 2); 
//     //  console.log(assignedDateObj)
//         return assignedDateObj < currentDate;  // Check if the assigned date is more than 1 hour ago
//     };
    

//     useEffect(() => {
//         // console.log('filteredJobs', filteredJobs);
//     }, [filteredJobs]);

//     return (
//         <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
//             <Table>
//                 <TableHead>
//                     <TableRow>
//                         <TableCell>Client Name</TableCell>
//                         <TableCell>Location</TableCell>
//                         <TableCell>Created At</TableCell>
//                         <TableCell>Assigned To</TableCell>
//                         <TableCell>Assignment Status</TableCell>
//                         <TableCell>Job Status</TableCell>
//                         <TableCell>Actions</TableCell>
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {filteredJobs.length > 0 ? (
//                         filteredJobs.map((job) => {
//                             let jobStatus = job.jobstatus
//                                 ? 'Complete'
//                                 : job.assignedstatus
//                                 ? 'Processing'
//                                 : 'Pending';

//                             // Check if job is assigned, job is not complete, and assigned_date is past
//                             if (job.assignedstatus && !job.jobstatus && job.jobAssigned && job.jobAssigned.assigned_date) {
//                                 const assignedDate = job.jobAssigned.assigned_date;
//                                 if (isDatePast(assignedDate)) {
//                                     jobStatus = 'Not Completed'; // Set to 'Not Completed' if past due
//                                 }
//                             }

//                             return (
//                                 <TableRow
//                                     key={job.id}
//                                     className="job-table-row"
//                                     sx={{
//                                         backgroundColor: job.assignedstatus ? '#e0f7fa' : '#ffebee',
//                                         '&:hover': {
//                                             backgroundColor: job.assignedstatus ? '#b2ebf2' : '#ffcdd2'
//                                         }
//                                     }}
//                                 >
//                                     <TableCell>{job.client_name}</TableCell>
//                                     <TableCell>{job.location}</TableCell>
//                                     <TableCell>{new Date(job.createdAt).toLocaleDateString()}</TableCell>
//                                     <TableCell>
//                                         {job.assignedstatus
//                                             ? jobAssignments.find((assignment) => assignment.job_id === job.id)?.worker.username || 'Unknown'
//                                             : 'Not Assigned'}
//                                     </TableCell>
//                                     <TableCell>
//                                         <Chip
//                                             label={job.assignedstatus ? 'Assigned' : 'Not Assigned'}
//                                             color={job.assignedstatus ? 'success' : 'error'}
//                                         />
//                                     </TableCell>
//                                     <TableCell>
//                                         <Chip
//                                             label={jobStatus}
//                                             color={
//                                                 jobStatus === 'Complete'
//                                                     ? 'success'
//                                                     : jobStatus === 'Processing'
//                                                     ? 'warning'
//                                                     : jobStatus === 'Not Completed'
//                                                     ? 'error'  // Set to 'error' for red color
//                                                     : 'default'
//                                             }
//                                         />
//                                     </TableCell>
//                                     <TableCell>
//                                      <div style={{display:'flex',flexDirection:'row'}} >
//                                      {!job.assignedstatus && (
//                                             <IconButton onClick={() => handleAssignOpen(job)} disabled={job.assignedstatus}>
//                                                 <Assignment />
//                                             </IconButton>
//                                         )}
//                                         {job.assignedstatus && !job.jobstatus  && (
//                                             <IconButton onClick={() => handleAssignOpenEdit(job)} disabled={job.jobstatus}>
//                                                 <Assignment />
//                                             </IconButton>
//                                         )}

//                                    {!job.jobstatus && (
//                                     <IconButton onClick={() => handleEditOpen(job)} disabled={job.jobstatus}>
//                                     <Edit  />
//                                 </IconButton>
//                                    )}     
//                                         {jobStatus === 'Complete' && (
//                                             <IconButton onClick={() => {
//                                                 nav('/feedback', { state: { JobId: job.id } });
//                                             }}>
//                                                 <Visibility />
//                                             </IconButton>
//                                         )}

//                                      </div>
//                                     </TableCell>
//                                 </TableRow>
//                             );
//                         })
//                     ) : (
//                         <TableRow>
//                             <TableCell colSpan={7}>
//                                 <Typography>No jobs available</Typography>
//                             </TableCell>
//                         </TableRow>
//                     )}
//                 </TableBody>
//             </Table>
//         </TableContainer>
//     );
// };

// export default JobTable;

import React, { useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, IconButton, Typography, Box
} from '@mui/material';
import { Assignment, Edit, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './table.css';

const JobTable = ({ filteredJobs, handleAssignOpenEdit, jobAssignments, handleAssignOpen, handleEditOpen }) => {
    const nav = useNavigate();

    // Helper function to check if the assigned date is past
    const isDatePast = (assignedDate) => {
        const currentDate = new Date();
        const assignedDateObj = new Date(assignedDate);
        assignedDateObj.setHours(assignedDateObj.getHours() + 2);
        return assignedDateObj < currentDate;  // Check if the assigned date is more than 2 hours ago
    };

    useEffect(() => {
        // You can log filteredJobs here if needed
    }, [filteredJobs]);

    return (
        <Box sx={{ overflowX: 'auto', padding: 2 }}>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Client Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Assigned To</TableCell>
                        <TableCell>Assignment Status</TableCell>
                        <TableCell>Job Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredJobs.length > 0 ? (
                        filteredJobs.map((job) => {
                            let jobStatus = job.jobstatus
                                ? 'Complete'
                                : job.assignedstatus
                                ? 'Processing'
                                : 'Pending';

                            // Check if job is assigned, job is not complete, and assigned_date is past
                            if (job.assignedstatus && !job.jobstatus && job.jobAssigned && job.jobAssigned.assigned_date) {
                                const assignedDate = job.jobAssigned.assigned_date;
                                if (isDatePast(assignedDate)) {
                                    jobStatus = 'Not Completed'; // Set to 'Not Completed' if past due
                                }
                            }

                            return (
                                <TableRow
                                    key={job.id}
                                    className="job-table-row"
                                    sx={{
                                        backgroundColor: job.assignedstatus ? '#e0f7fa' : '#ffebee',
                                        '&:hover': {
                                            backgroundColor: job.assignedstatus ? '#b2ebf2' : '#ffcdd2'
                                        }
                                    }}
                                >
                                    <TableCell>{job.client_name}</TableCell>
                                    <TableCell>{job.location}</TableCell>
                                    <TableCell>{new Date(job.createdAt).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        {job.assignedstatus
                                            ? jobAssignments.find((assignment) => assignment.job_id === job.id)?.worker.username || 'Unknown'
                                            : 'Not Assigned'}
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={job.assignedstatus ? 'Assigned' : 'Not Assigned'}
                                            color={job.assignedstatus ? 'success' : 'error'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={jobStatus}
                                            color={
                                                jobStatus === 'Complete'
                                                    ? 'success'
                                                    : jobStatus === 'Processing'
                                                    ? 'warning'
                                                    : jobStatus === 'Not Completed'
                                                    ? 'error'
                                                    : 'default'
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                            {!job.assignedstatus && (
                                                <IconButton onClick={() => handleAssignOpen(job)} disabled={job.assignedstatus}>
                                                    <Assignment />
                                                </IconButton>
                                            )}
                                            {job.assignedstatus && !job.jobstatus && (
                                                <IconButton onClick={() => handleAssignOpenEdit(job)} disabled={job.jobstatus}>
                                                    <Assignment />
                                                </IconButton>
                                            )}
                                            {!job.jobstatus && (
                                                <IconButton onClick={() => handleEditOpen(job)} disabled={job.jobstatus}>
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {jobStatus === 'Complete' && (
                                                <IconButton onClick={() => {
                                                    nav('/feedback', { state: { JobId: job.id } });
                                                }}>
                                                    <Visibility />
                                                </IconButton>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Typography align="center">No jobs available</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
    );
};

export default JobTable;

