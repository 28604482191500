import React from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import axios from 'axios';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const DeleteJobModal = ({ open, onClose, jobId, onJobDeleted,token }) => {
    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}apiv1/job/${jobId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            onJobDeleted(); // Notify parent component to refresh jobs list
            onClose(); // Close the modal
        } catch (error) {
            console.error('Error deleting job:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" gutterBottom>
                    Confirm Deletion
                </Typography>
                <Typography variant="body1">
                Are you sure you want to delete this job?
                 This action will also remove the job from the Driver Report
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" color="error" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteJobModal;
