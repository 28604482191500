import React, { useEffect, useState } from 'react';
import {
    Box, Modal, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Button
} from '@mui/material';
import dummy from '../../asste/userdummy.ico';

const EditAssignJobModal = ({ open, handleClose, workers, selectedWorker, setSelectedWorker, assignedDate, setAssignedDate, handleAssignSubmit, token, jobToAssign }) => {
   

    const handleJobAssignedPost = async () => {
        try {
            await handleAssignSubmit();
        } catch (error) {
            // console.log("Something went wrong assigning the job", error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="assign-job-modal"
            aria-describedby="assign-job-description"
        >
            <Box sx={{ p: 4, bgcolor: 'background.paper', width: '300px', margin: 'auto', marginTop: '15%' }}>
                <Typography variant="h6" gutterBottom>Assign Job</Typography>

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="worker-label">Select Driver</InputLabel>
                    <Select
                        labelId="worker-label"
                        value={selectedWorker.id}
                        onChange={(e) => {
                            const worker = workers.find(worker => worker.id === e.target.value);
                            setSelectedWorker({ id: worker.id, fcmToken: worker.fcmToken, username: worker.username });
                        }}
                        label="Worker"
                    >
                        {workers.map(worker => (
                            <MenuItem key={worker.id} value={worker.id} style={{ display: 'flex', alignItems: 'center' }}>
                                {worker.profilepic && (
                                    <img
                                        src={worker.profilepic}
                                        width={25}
                                        height={25}
                                        alt={worker.username}
                                        style={{
                                            marginRight: '8px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                )}
                                <span style={{ fontWeight: 'bold' }}>{worker.username}</span>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    label="Assigned Date and Time"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    sx={{ mb: 2 }}
                    value={assignedDate}
                    onChange={(e) => setAssignedDate(e.target.value)}
                />

                <Button variant="contained" color="primary" onClick={handleJobAssignedPost}>
                    Submit
                </Button>
            </Box>
        </Modal>
    );
};

export default EditAssignJobModal;

