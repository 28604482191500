// import React, { useEffect, useState } from 'react';
// import { Box, Button, Typography, Chip } from '@mui/material';
// import axios from 'axios';
// import moment from 'moment';

// const daysInMonth = (month, year) => {
//     return new Date(year, month + 1, 0).getDate();
// };

// const JobAssignmentsCalendar = ({ token }) => {
//     const [jobsByDate, setJobsByDate] = useState({});
//     const [selectedDate, setSelectedDate] = useState(null);
//     const [selectedJobs, setSelectedJobs] = useState([]);

//     const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
//     const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

//     useEffect(() => {
//         fetchAssignedJobs();
//     }, [currentMonth, currentYear]);

//     const fetchAssignedJobs = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/job-assigned`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             // console.log(response.data);

//             const jobs = response.data;
//             const jobsGroupedByDate = {};

//             jobs.forEach(job => {
//                 const assignedDate = moment(job.assigned_date).format('YYYY-MM-DD');
//                 if (!jobsGroupedByDate[assignedDate]) {
//                     jobsGroupedByDate[assignedDate] = [];
//                 }
//                 jobsGroupedByDate[assignedDate].push(job);
//             });

//             setJobsByDate(jobsGroupedByDate);
//         } catch (error) {
//             // console.error("Error fetching assigned jobs:", error);
//         }
//     };

//     // Helper function to determine job status (Pending, In Process, or Completed)
//     const getJobStatus = (assignedDate, jobStatus) => {
//         const currentDate = moment().format('YYYY-MM-DD'); // Today's date in 'YYYY-MM-DD' format
//         const jobAssignedDate = moment(assignedDate).format('YYYY-MM-DD');
// //  console.log('jobstatus', jobStatus)
//         // Check for completed status (true)
//         if (jobStatus === true) {
//             return { label: 'Completed', color: '#4CAF50' }; // Green for completed jobs
//         }
//         // If the job assigned date is in the past
//         else if (jobAssignedDate < currentDate) {
//             return { label: 'Pending', color: '#F44336' }; // Red for past pending jobs
//         } 
//         // If the job is upcoming or still in progress
//         else  {
//             return { label: 'In Process', color: '#FF9800' }; // Orange for future jobs
//         }
//     };

//     const handleDateClick = (date) => {
//         const dateStr = moment(date).format('YYYY-MM-DD');
//         setSelectedJobs(jobsByDate[dateStr] || []);
//         setSelectedDate(date);
//     };

//     const renderCalendarDays = () => {
//         const days = [];
//         const totalDays = daysInMonth(currentMonth, currentYear);

//         for (let i = 1; i <= totalDays; i++) {
//             const dateStr = moment([currentYear, currentMonth, i]).format('YYYY-MM-DD');
//             const jobCount = jobsByDate[dateStr] ? jobsByDate[dateStr].length : 0;

//             // Get the first job's status for the current date
//             const jobStatus = jobsByDate[dateStr]?.map(job => getJobStatus(job.assigned_date, job.job.jobstatus))[0]; // Get the first job status

//             // Default background color (neutral) if no jobs are assigned on this date
//             const backgroundColor = jobCount > 0 ? jobStatus?.color : '#f5f5f5';

//             days.push(
//                 <Box
//                     key={i}
//                     onClick={() => handleDateClick(new Date(currentYear, currentMonth, i))}
//                     sx={{
//                         width: '90px',
//                         height: '90px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         border: '1px solid #ddd',
//                         margin: '5px',
//                         cursor: 'pointer',
//                         backgroundColor: backgroundColor, // Apply background color here
//                         borderRadius: '50%',
//                         transition: 'all 0.3s ease',
//                         '&:hover': {
//                             transform: 'scale(1.05)',
//                             backgroundColor: backgroundColor, // Maintain background color on hover
//                         },
//                     }}
//                 >
//                     <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>{i}</Typography>
//                     {jobCount > 0 && (
//                         <Box sx={{
//                             backgroundColor: '#0079ae',
//                             color: '#fff',
//                             borderRadius: '50%',
//                             width: '28px',
//                             height: '28px',
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             marginTop: '8px',
//                             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//                         }}>
//                             {jobCount}
//                         </Box>
//                     )}
//                 </Box>
//             );
//         }
//         return days;
//     };

//     const handleNextMonth = () => {
//         if (currentMonth === 11) {
//             setCurrentMonth(0);
//             setCurrentYear(currentYear + 1);
//         } else {
//             setCurrentMonth(currentMonth + 1);
//         }
//     };

//     const handlePreviousMonth = () => {
//         if (currentMonth === 0) {
//             setCurrentMonth(11);
//             setCurrentYear(currentYear - 1);
//         } else {
//             setCurrentMonth(currentMonth - 1);
//         }
//     };

//     const handleToday = () => {
//         setCurrentMonth(new Date().getMonth());
//         setCurrentYear(new Date().getFullYear());
//     };

//     return (
//         <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//             <Button
//                 variant="contained"
//                 sx={{
//                     mb: 3,
//                     background: "#0079ae",
//                     color: '#fff',
//                     '&:hover': { background: '#005f8d' },
//                     fontWeight: 'bold',
//                     padding: '10px 20px',
//                     borderRadius: '25px',
//                 }}
//             >
//                 Create Job
//             </Button>

//             <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', color: '#333' }}>
//                 Jobs for {moment([currentYear, currentMonth]).format('MMMM YYYY')}
//             </Typography>

//             {/* Navigation Buttons */}
//             <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
//                 <Button
//                     onClick={handlePreviousMonth}
//                     variant="outlined"
//                     sx={{
//                         borderRadius: '20px',
//                         color: '#0079ae',
//                         borderColor: '#0079ae',
//                         '&:hover': {
//                             backgroundColor: '#0079ae',
//                             color: '#fff',
//                         },
//                     }}
//                 >
//                     Previous Month
//                 </Button>
//                 <Button
//                     onClick={handleNextMonth}
//                     variant="outlined"
//                     sx={{
//                         borderRadius: '20px',
//                         color: '#0079ae',
//                         borderColor: '#0079ae',
//                         '&:hover': {
//                             backgroundColor: '#0079ae',
//                             color: '#fff',
//                         },
//                     }}
//                 >
//                     Next Month
//                 </Button>
//             </Box>

//             {/* Calendar Layout */}
//             <Box sx={{
//                 display: 'grid',
//                 gridTemplateColumns: 'repeat(7, 1fr)',
//                 gridGap: '10px',
//                 paddingBottom: '20px',
//             }}>
//                 {renderCalendarDays()}
//             </Box>

//             {/* Jobs for Selected Date */}
//             {selectedDate && (
//                 <Box>
//                     <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#0079ae', mb: 2 }}>
//                         Jobs on {moment(selectedDate).format('MMMM Do, YYYY')}
//                     </Typography>
//                     {selectedJobs.length > 0 ? (
//                         <Box sx={{ mt: 2 }}>
//                             {selectedJobs.map(job => {
//                                 const { label, color } = getJobStatus(job.assigned_date, job.job.jobstatus);
//                                 return (
//                                     <Box
//                                         key={job.id}
//                                         sx={{
//                                             background: '#fff',
//                                             p: 3,
//                                             mb: 2,
//                                             borderRadius: '8px',
//                                             boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//                                             borderLeft: `4px solid ${color === '#4CAF50' ? '#4CAF50' : color === '#FF9800' ? '#FF9800' : '#F44336'}`,
//                                         }}
//                                     >
//                                         <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
//                                             Job Title: <span style={{ color: '#0079ae' }}>{job.job.job_title}</span>
//                                         </Typography>
//                                         <Typography variant="body1" sx={{ mb: 1 }}>
//                                             Assigned To: <span style={{ fontWeight: '500', color: '#333' }}>{job.worker.username}</span>
//                                         </Typography>
//                                         <Typography variant="body1" sx={{ mb: 1 }}>
//                                             Assigned Date: {moment(job.assigned_date).format('MMMM Do, YYYY')}
//                                         </Typography>
//                                         <Typography variant="body1" sx={{ mb: 1 }}>
//                                             Job Status:
//                                             <Chip
//                                                 label={label}
//                                                 color={color === '#4CAF50' ? 'success' : color === '#FF9800' ? 'warning' : 'error'}
//                                                 sx={{ marginLeft: 1 }}
//                                             />
//                                         </Typography>
//                                     </Box>
//                                 );
//                             })}
//                         </Box>
//                     ) : (
//                         <Typography sx={{ color: '#888' }}>No jobs assigned on this date.</Typography>
//                     )}
//                 </Box>
//             )}
//         </div>
//     );
// };

// export default JobAssignmentsCalendar;
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Chip } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
};

const JobAssignmentsCalendar = ({ token }) => {
    const [jobsByDate, setJobsByDate] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedJobs, setSelectedJobs] = useState([]);

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        fetchAssignedJobs();
    }, [currentMonth, currentYear]);

    const fetchAssignedJobs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/job-assigned`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const jobs = response.data;
            const jobsGroupedByDate = {};

            jobs.forEach(job => {
                const assignedDate = moment(job.assigned_date).format('YYYY-MM-DD');
                if (!jobsGroupedByDate[assignedDate]) {
                    jobsGroupedByDate[assignedDate] = [];
                }
                jobsGroupedByDate[assignedDate].push(job);
            });

            setJobsByDate(jobsGroupedByDate);
        } catch (error) {
            console.error("Error fetching assigned jobs:", error);
        }
    };

    const getJobStatus = (assignedDate, jobStatus) => {
        const currentDate = moment().format('YYYY-MM-DD');
        const jobAssignedDate = moment(assignedDate).format('YYYY-MM-DD');

        if (jobStatus === true) {
            return { label: 'Completed', color: '#4CAF50' };
        } else if (jobAssignedDate < currentDate) {
            return { label: 'Pending', color: '#F44336' };
        } else {
            return { label: 'In Process', color: '#FF9800' };
        }
    };

    const handleDateClick = (date) => {
        const dateStr = moment(date).format('YYYY-MM-DD');
        setSelectedJobs(jobsByDate[dateStr] || []);
        setSelectedDate(date);
    };

    const renderCalendarDays = () => {
        const days = [];
        const totalDays = daysInMonth(currentMonth, currentYear);

        for (let i = 1; i <= totalDays; i++) {
            const dateStr = moment([currentYear, currentMonth, i]).format('YYYY-MM-DD');
            const jobCount = jobsByDate[dateStr] ? jobsByDate[dateStr].length : 0;
            const jobStatus = jobsByDate[dateStr]?.map(job => getJobStatus(job.assigned_date, job.job.jobstatus))[0];

            const backgroundColor = jobCount > 0 ? jobStatus?.color : '#f5f5f5';

            days.push(
                <Box
                    key={i}
                    onClick={() => handleDateClick(new Date(currentYear, currentMonth, i))}
                    sx={{
                        width: { xs: '80px', sm: '90px' }, // Responsive width
                        height: { xs: '80px', sm: '90px' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #ddd',
                        margin: '5px',
                        cursor: 'pointer',
                        backgroundColor: backgroundColor,
                        borderRadius: '50%',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.05)',
                            backgroundColor: backgroundColor,
                        },
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>{i}</Typography>
                    {jobCount > 0 && (
                        <Box sx={{
                            backgroundColor: '#0079ae',
                            color: '#fff',
                            borderRadius: '50%',
                            width: '28px',
                            height: '28px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}>
                            {jobCount}
                        </Box>
                    )}
                </Box>
            );
        }
        return days;
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const handlePreviousMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const handleToday = () => {
        setCurrentMonth(new Date().getMonth());
        setCurrentYear(new Date().getFullYear());
    };

    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <Button
                variant="contained"
                sx={{
                    mb: 3,
                    background: "#0079ae",
                    color: '#fff',
                    '&:hover': { background: '#005f8d' },
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    borderRadius: '25px',
                }}
            >
                Create Job
            </Button>

            <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', color: '#333' }}>
                Jobs for {moment([currentYear, currentMonth]).format('MMMM YYYY')}
            </Typography>

            {/* Navigation Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Button
                    onClick={handlePreviousMonth}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        color: '#0079ae',
                        borderColor: '#0079ae',
                        '&:hover': {
                            backgroundColor: '#0079ae',
                            color: '#fff',
                        },
                    }}
                >
                    Previous Month
                </Button>
                <Button
                    onClick={handleNextMonth}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        color: '#0079ae',
                        borderColor: '#0079ae',
                        '&:hover': {
                            backgroundColor: '#0079ae',
                            color: '#fff',
                        },
                    }}
                >
                    Next Month
                </Button>
            </Box>

            {/* Calendar Layout */}
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: { xs: 'repeat(3, 1fr)', sm: 'repeat(4, 1fr)', md: 'repeat(7, 1fr)' },
                gridGap: '10px',
                paddingBottom: '20px',
            }}>
                {renderCalendarDays()}
            </Box>

            {/* Jobs for Selected Date */}
            {selectedDate && (
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#0079ae', mb: 2 }}>
                        Jobs on {moment(selectedDate).format('MMMM Do, YYYY')}
                    </Typography>
                    {selectedJobs.length > 0 ? (
                        <Box sx={{ mt: 2 }}>
                            {selectedJobs.map(job => {
                                const { label, color } = getJobStatus(job.assigned_date, job.job.jobstatus);
                                return (
                                    <Box
                                        key={job.id}
                                        sx={{
                                            background: '#fff',
                                            p: 3,
                                            mb: 2,
                                            borderRadius: '8px',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            borderLeft: `4px solid ${color}`,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                            Job Title: <span style={{ color: '#0079ae' }}>{job.job.job_title}</span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Assigned To: <span style={{ fontWeight: '500', color: '#333' }}>{job.worker.username}</span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Assigned Date: {moment(job.assigned_date).format('MMMM Do, YYYY')}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Job Status:
                                            <Chip
                                                label={label}
                                                color={color === '#4CAF50' ? 'success' : color === '#FF9800' ? 'warning' : 'error'}
                                                sx={{ marginLeft: 1 }}
                                            />
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : (
                        <Typography sx={{ color: '#888' }}>No jobs assigned on this date.</Typography>
                    )}
                </Box>
            )}
        </div>
    );
};

export default JobAssignmentsCalendar;
