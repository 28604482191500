import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Visibility } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Select,
  MenuItem,
  AppBar,
  Toolbar,
  Container,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import './Dashbord.css'; // Import your custom styles
import { useNavigate } from 'react-router-dom';

const Dashboard = ({ token }) => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [vehicleCount, setVehicleCount] = useState(0);
  const [feedbackCount, setFeedbackCount] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [vehicles,setVehicles]=useState([])
  const nav = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/get/job`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setJobs(response.data);
        filterJobs(response.data);
        prepareChartData(response.data);
        setLoading(false);
      } catch (error) {
        // console.error('Error fetching jobs:', error);
        setLoading(false);
      }
    };

    const fetchVehicles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/vehicles/get/all`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setVehicles(response.data); // Store all vehicles
      } catch (error) {
        // console.error('Error fetching vehicles:', error);
      }
    };

    const fetchFeedbacks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/feedbacks`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFeedback(response.data); // Store all feedbacks
      } catch (error) {
        // console.error('Error fetching feedbacks:', error);
      }
    };

    fetchJobs();
    fetchVehicles();
    fetchFeedbacks();
  }, [token]);

  useEffect(() => {
    filterJobs(jobs);
  }, [filter, jobs]);

  useEffect(() => {
    const filteredVehicles = filterDataByDate(vehicles); // Filter based on the date
    setVehicleCount(filteredVehicles.length);
  }, [vehicles, filter,]);

  useEffect(() => {
    const filteredFeedbacks = filterDataByDate(feedback); // Filter based on the date
    setFeedbackCount(filteredFeedbacks.length);
  }, [feedback, filter,modalOpen]);

  const filterJobs = (jobs) => {
    const now = new Date();
    const filtered = jobs.filter(job => {
      const jobDate = new Date(job.createdAt);
      switch (filter) {
        case 'today':
          return jobDate.toDateString() === now.toDateString();
        case '7days':
          return (now - jobDate) / (1000 * 60 * 60 * 24) <= 7;
        case '1month':
          return jobDate.getMonth() === now.getMonth() && jobDate.getFullYear() === now.getFullYear();
        case 'last_month':
          const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          return jobDate.getMonth() === lastMonth.getMonth() && jobDate.getFullYear() === lastMonth.getFullYear();
        case 'this_year':
          return jobDate.getFullYear() === now.getFullYear();
        case 'all':
          return true;
        default:
          return false;
      }
    });
    setFilteredJobs(filtered);
  };

  const filterDataByDate = (data) => {
    const now = new Date();
    return data.filter(item => {
      const itemDate = new Date(item.createdAt);
      switch (filter) {
        case 'today':
          return itemDate.toDateString() === now.toDateString();
        case '7days':
          return (now - itemDate) / (1000 * 60 * 60 * 24) <= 7;
        case '1month':
          return itemDate.getMonth() === now.getMonth() && itemDate.getFullYear() === now.getFullYear();
        case 'last_month':
          const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          return itemDate.getMonth() === lastMonth.getMonth() && itemDate.getFullYear() === lastMonth.getFullYear();
        case 'this_year':
          return itemDate.getFullYear() === now.getFullYear();
        case 'all':
          return true;
        default:
          return false;
      }
    });
  };

  const prepareChartData = (jobs) => {
    const dataMap = {};
    feedback.forEach(feed => {
      const jobDate = new Date(feed.createdAt).toDateString();
      if (!dataMap[jobDate]) {
        dataMap[jobDate] = { date: jobDate, completed: 0 };
      }
      if (feed.jobStatus.completed_status) { // Assuming 'completed_status' is a property of feedback
        dataMap[jobDate].completed += 1;
      }
    });
    setChartData(Object.values(dataMap));
  };

  const handleFilterChange = (filterOption) => {
    setFilter(filterOption);
  };

  const totalJobs = filteredJobs.length;
  const processingJobs = filteredJobs.filter(job => job.assignedstatus && !job.jobstatus).length;
  const pendingJobs = filteredJobs.filter(job => !job.assignedstatus).length;

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setPage(0); // Reset page when modal is closed
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const filteredFeedbacks = filterDataByDate(feedback);







  const Feedbacks = filteredFeedbacks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
  if(loading) return <Typography>Loding....</Typography>

  return (
    <Container maxWidth="lg" className="dashboard-container">
      <AppBar position="static" sx={{ background: "#34495e" }}>
        <Toolbar>
          <IconButton onClick={() => nav('/calendar')} edge="start" color="inherit">
            <CalendarTodayIcon />
          </IconButton>
          <IconButton edge="end" color="inherit">
            <SettingsIcon />
          </IconButton>
          <Select sx={{ background: '#fff' , fontFamily:'Inter Tight',}} value={filter} onChange={(e) => handleFilterChange(e.target.value)} variant="outlined" style={{ marginLeft: 'auto' }}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="7days">Last 7 Days</MenuItem>
            <MenuItem value="1month">This Month</MenuItem>
            <MenuItem value="last_month">Last Month</MenuItem>
            <MenuItem value="this_year">This Year</MenuItem>
          </Select>
        </Toolbar>
      </AppBar>

      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="dashboard-card" style={{ backgroundColor: '#f5f5f5' }}>
            <CardContent onClick={() => nav('/jobs', { state: { DataStatus: filter } })}>
              <Typography sx={{ fontFamily:'Inter Tight',}} variant="h6">Total Jobs</Typography>
              <Typography sx={{ fontFamily:'Inter Tight',}} variant="h4">{totalJobs}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="dashboard-card" style={{ backgroundColor: '#ffe0b2' }}>
            <CardContent onClick={() => nav('/jobs', { state: { Processing: "Processing", DataStatus: filter } })}>
              <Typography sx={{ fontFamily:'Inter Tight',}} variant="h6">Processing Jobs</Typography>
              <Typography sx={{ fontFamily:'Inter Tight',}} variant="h4">{processingJobs}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="dashboard-card" style={{ backgroundColor: '#ffccbc' }}>
            <CardContent onClick={() => nav('/jobs', { state: { Pending: "Pending", DataStatus: filter } })}>
              <Typography variant="h6">Pending Jobs</Typography>
              <Typography variant="h4">{pendingJobs}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="dashboard-card" style={{ backgroundColor: '#c8e6c9' }} onClick={handleOpenModal}>
            <CardContent>
              <Typography variant="h6">Completed Jobs</Typography>
              <Typography variant="h4">{feedbackCount}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="dashboard-card" style={{ backgroundColor: '#bbdefb' }} onClick={() => nav('/vehicles')}>
            <CardContent>
              <Typography sx={{ fontFamily:'Inter Tight',}} variant="h6">Total Vehicles</Typography>
              <Typography  sx={{ fontFamily:'Inter Tight',}}variant="h4">{vehicleCount}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          {/* Uncomment if needed for chart */}
          {/* <Card>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                Job Overview
              </Typography>
              <LineChart width={700} height={300} data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="completed" stroke="#82ca9d" />
              </LineChart>
            </CardContent>
          </Card> */}
        </Grid>
      </Grid>

      {/* Feedback Modal */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div style={{ padding: '20px', backgroundColor: 'white', margin: '50px auto', width: '80%', maxHeight: '80%', overflowY: 'auto' }}>
          <Typography variant="h6" sx={{ fontFamily:'Inter Tight',}} gutterBottom>Feedback List</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontFamily:'Inter Tight',}}>Date</TableCell>
                  <TableCell sx={{ fontFamily:'Inter Tight',}}>Customer Name</TableCell>
                  <TableCell sx={{ fontFamily:'Inter Tight',}}>Origin of Waste</TableCell>
                  <TableCell sx={{ fontFamily:'Inter Tight',}}>Reference No</TableCell>
                  <TableCell sx={{ fontFamily:'Inter Tight',}}>Date of Destruction</TableCell>
                  <TableCell sx={{ fontFamily:'Inter Tight',}}>Weight Docket No</TableCell>
                  <TableCell sx={{ fontFamily:'Inter Tight',}}>Total Tonnes (kg)</TableCell>
                  <TableCell sx={{ fontFamily:'Inter Tight',}}>Details of Materials Collected</TableCell>
<TableCell sx={{ fontFamily:'Inter Tight',}}>View Feedback</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Feedbacks.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell sx={{ fontFamily:'Inter Tight',}}>{new Date(item.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell sx={{ fontFamily:'Inter Tight',}}>{item.customer_name}</TableCell>
                    <TableCell sx={{ fontFamily:'Inter Tight',}}>{item.origin_of_waste}</TableCell>
                    <TableCell>{item.ref_no}</TableCell>
                    <TableCell sx={{ fontFamily:'Inter Tight',}}>{new Date(item.date_of_destruction).toLocaleString()}</TableCell>
                    <TableCell sx={{ fontFamily:'Inter Tight',}}>{item.weight_docket_no}</TableCell>
                    <TableCell sx={{ fontFamily:'Inter Tight',}}>{item.total_tonnes_kg}</TableCell>
                    <TableCell sx={{ fontFamily:'Inter Tight',}}>{item.details_of_materials_collected}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => nav('/feedback', { state: { JobId: item.job_id } })}>
                        <Visibility />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={feedback.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </div>
      </Modal>
    </Container>
  );
};

export default Dashboard;
