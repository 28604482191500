// src/theme.js or src/muiTheme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Inter Tight'", // Applying the custom font without sans-serif fallback
    h1: {
      fontFamily: "'Inter Tight'", // Specific control over h1 heading
    },
    h2: {
      fontFamily: "'Inter Tight'", // Control over h2 heading
    },
    body1: {
      fontFamily: "'Inter Tight'", // Control for body text
    },
  },
  components: {
    // Customize Table components
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'collapse', // Optional: You can set other styles like borders, spacing
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '16px', // Customize padding inside table cells
          border: '1px solid #ddd', // Optional: Add border around cells
          textAlign: 'center', // Optional: Center the text in the cells
        },
      },
    },
    // Customize Modal (Dialog Box)
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px', // Rounded corners for the modal box
          padding: '20px', // Padding inside the modal box
          maxWidth: '600px', // Optional: Limit the maximum width of the modal box
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)', // Optional: Add a soft shadow
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter Tight'", // Customize modal title font
          fontSize: '1.25rem', // Optional: Adjust modal title font size
          fontWeight: 'bold', // Optional: Make the title bold
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter Tight'", // Customize modal content font
          fontSize: '1rem', // Adjust font size for content
          color: '#333', // Optional: Set text color for better contrast
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '12px', // Optional: Add padding to action buttons inside the modal
          justifyContent: 'flex-end', // Optional: Align the action buttons to the right
        },
      },
    },
  },
});

export default theme;

