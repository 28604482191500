// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-table-row {
  /* Smooth background color change */
    cursor: pointer; /* Change cursor to pointer */
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .job-table-row:hover {
    transform: translateY(-5px);
    background-color: #b2ebf2; /* Change background color on hover */
  }

  /* table.css */

.job-table-row {
  transition: background-color 0.3s;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .MuiTableCell-root {
      padding: 8px; /* Less padding for mobile */
      font-size: 12px; /* Smaller font size for mobile */
  }
  .job-table-row {
      flex-direction: column; /* Stack items vertically if necessary */
  }
}

`, "",{"version":3,"sources":["webpack://./src/Component/Job_Modal/table.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;IACjC,eAAe,EAAE,6BAA6B;IAC9C,2CAA2C;EAC7C;;EAEA;IACE,2BAA2B;IAC3B,yBAAyB,EAAE,qCAAqC;EAClE;;EAEA,cAAc;;AAEhB;EACE,iCAAiC;AACnC;;AAEA,2BAA2B;AAC3B;EACE;MACI,YAAY,EAAE,4BAA4B;MAC1C,eAAe,EAAE,iCAAiC;EACtD;EACA;MACI,sBAAsB,EAAE,wCAAwC;EACpE;AACF","sourcesContent":[".job-table-row {\r\n  /* Smooth background color change */\r\n    cursor: pointer; /* Change cursor to pointer */\r\n    transition: transform 0.2s, box-shadow 0.2s;\r\n  }\r\n  \r\n  .job-table-row:hover {\r\n    transform: translateY(-5px);\r\n    background-color: #b2ebf2; /* Change background color on hover */\r\n  }\r\n\r\n  /* table.css */\r\n\r\n.job-table-row {\r\n  transition: background-color 0.3s;\r\n}\r\n\r\n/* Responsive adjustments */\r\n@media (max-width: 600px) {\r\n  .MuiTableCell-root {\r\n      padding: 8px; /* Less padding for mobile */\r\n      font-size: 12px; /* Smaller font size for mobile */\r\n  }\r\n  .job-table-row {\r\n      flex-direction: column; /* Stack items vertically if necessary */\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
