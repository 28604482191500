
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Box, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import ClearIcon from '@mui/icons-material/Clear';

const FeedbackEdite = ({ token, JobId, open, onClose }) => {
  const [feedbackData, setFeedbackData] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const pdfRef = useRef(null); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

  // Fetch feedback data and signatures
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/feedbacks/${JobId}`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Add the token here
            }
          });
          const feedbacks = response.data;
        
     
          const updatedFeedbacks = await Promise.all(feedbacks.map(fetchSignature));
          setFeedbackData(updatedFeedbacks);
        } else {
          // console.log('Token is missing');
        }
      } catch (error) {
        // console.error('Error fetching feedback data:', error);
      }
    };

    if (JobId) {
      fetchData();
    }
  }, [JobId, token]);


  const fetchSignature = async (detail) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}images/${detail.client_digital_signature}`, {
        responseType: 'arraybuffer', // Fetch image as an arraybuffer
      });
      const base64String = Buffer.from(response.data, 'binary').toString('base64');
      const base64Url = `data:image/jpeg;base64,${base64String}`;
      return { ...detail, client_signature_url: base64Url }; // Store Base64 URL
    } catch (error) {
      console.error('Error fetching digital signature:', error);
      return { ...detail, client_signature_url: '' }; // Fallback to empty string
    }
  };

  // Handle changes in the input fields
  const handleInputChange = (e, field, index) => {
    const updatedFeedbackData = [...feedbackData];
    updatedFeedbackData[index][field] = e.target.value;
    setFeedbackData(updatedFeedbackData);
  };


 

  useEffect(() => {
    const checkImagesLoaded = () => {
      const images = document.querySelectorAll('img');
      const loadedImages = Array.from(images).filter(image => image.complete);
      if (loadedImages.length === images.length) {
        setImagesLoaded(true);
      }
    };

    // Listen for the load event on each image
    const images = document.querySelectorAll('img');
    images.forEach(image => {
      image.onload = checkImagesLoaded;
    });

    // Check initial load
    checkImagesLoaded();
  }, []);

 

  // Update the feedback data via API
  const handleUpdate = async (index) => {
    const updatedFeedback = feedbackData[index];
    try {
      
      const response = await axios.put(`${process.env.REACT_APP_API_URL}apiv1/feedbacks/edite/${updatedFeedback.id}`, updatedFeedback, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      // console.log("Feedback updated successfully:", response.data);
      setSnackbarMessage('Feedback updated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      onClose()
    } catch (error) {
      // console.error('Error updating feedback:', error);
      setSnackbarMessage('Failed to update feedback');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Function to format the timestamp into YYYY-MM-DD format
  const formatDate = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return '';
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (!feedbackData) {
    return <p>Loading...</p>;
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="feedback-edit-modal" aria-describedby="feedback-edit-modal-description">
      <Box
        sx={{
          marginTop: '3%',
          marginBottom: '3%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '100vh',
          overflow: 'scroll',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          display: 'relative',  // Add this to enable positioning of the icon
        }}
      >
        {/* Clear Icon in the top-right corner */}
        <ClearIcon
          onClick={onClose}  // Call the onClose function when the icon is clicked
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer',  // Make it clickable
            color: '#142587',  // Optional: Customize the icon color
            fontSize: '30px',   // Optional: Customize the icon size
          }}
        />
        <div className="containersedite">

          <div className="maincardsedite" ref={pdfRef} style={{ width: '100%', overflow: 'hidden' }}>
            {feedbackData.map((detail, index) => (
              <div key={index} className="cardsedit" style={{ pageBreakInside: 'avoid', fontSize: '10px', lineHeight: '1' }}>
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <h2 style={{ borderBottom: '2px solid #142587', margin: '10px', paddingBottom: '20px', fontFamily: 'Inter Tight' }}>
                    Document Shredding - Electronic Equipment Recycling
                  </h2>
                </div>

                <div className='frist_coledit'>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <p style={{ margin: '5px 0', fontFamily: 'Inter Tight', fontSize: '15px' }}>
                      Fairymount, Castlerea, Co Roscommon
                    </p>
                  </div>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <div>
                      <p style={{ margin: '5px 0', fontFamily: 'Inter Tight', fontSize: '15px' }}>
                        <b style={{ color: '#142587' }}>Tel:</b> 1800987220 <b style={{ color: '#142587' }}>Email:</b> info@allstarshredding.ie <b style={{ color: '#142587' }}>Web:</b> www.allstarshredding.ie
                      </p>
                    </div>
                  </div>
                  <h1 style={{ display: 'flex', width: '100%', justifyContent: 'center', color: '#142587' }}>
                    Waste Collection Docket
                  </h1>
                </div>
                <div className="frist_section">
                  <div>
                    <div className="rowedite">
                      <p className="titleedite">Customer Name:</p>
                      <input
                        type="text"
                        value={detail.customer_name}
                        onChange={(e) => handleInputChange(e, 'customer_name', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    <div className="rowedite">
                      <p className="titleedite">Email:</p>
                      <input
                        type="text"
                        value={detail.email}
                        onChange={(e) => handleInputChange(e, 'email', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    <div className="rowedite">
                      <p className="titleedite">Phone:</p>
                      <input
                        type="text"
                        value={detail.phone_no}
                        onChange={(e) => handleInputChange(e, 'phone_no', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    <div className="rowedite">
                      <p className="titleedite">Customer Address:</p>
                      <input
                        type="text"
                        value={detail.customer_address}
                        onChange={(e) => handleInputChange(e, 'customer_address', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    <div className="rowedite">
                      <p className="titleedite">Town/City:</p>
                      <input
                        type="text"
                        value={detail.city}
                        onChange={(e) => handleInputChange(e, 'city', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    <div className="rowedite">
                      <p className="titleedite">County:</p>
                      <input
                        type="text"
                        value={detail.county}
                        onChange={(e) => handleInputChange(e, 'county', index)}
                        className="bottomborderedite"
                      />
                    </div>
                  
                  </div>

                  <div>
                    <div className="rowedite">
                      <p className="titleedite">Date of Destruction:</p>
                      <input
                        type="date"
                        value={formatDate(detail.date_of_destruction)}
                        onChange={(e) => handleInputChange(e, 'date_of_destruction', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    <div className="rowedite">
                      <p className="titleedite">Total Tonnes/Kg:</p>
                      <input
                        type="text"
                        value={detail.total_tonnes_kg}
                        onChange={(e) => handleInputChange(e, 'total_tonnes_kg', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    {/* <div className="rowedite">
                      <p className="titleedite">Weight Docket No:</p>
                      <input
                        type="text"
                        value={detail.weight_docket_no}
                        onChange={(e) => handleInputChange(e, 'weight_docket_no', index)}
                        className="bottomborderedite"
                      />
                    </div> */}
                    <div className="rowedite">
                      <p className="titleedite">Eire Code:</p>
                      <input
                        type="text"
                        value={detail.eire_code}
                        onChange={(e) => handleInputChange(e, 'eire_code', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    <div className="rowedite">
                      <p className="titleedite">Customer Representative Name:</p>
                      <input
                        type="text"
                        value={detail.customer_representative_name}
                        onChange={(e) => handleInputChange(e, 'customer_representative_name', index)}
                        className="bottomborderedite"
                      />
                    </div>
                    <div className="rowedite">
                      <p className="titleedite">Message:</p>
                      <textarea
                        value={detail.meassge}
                        onChange={(e) => handleInputChange(e, 'meassge', index)}
                        className="bottomborderedite"
                      />
                    </div>
                  </div>
                </div>

                <div className="detelisofmaterialsedite">
                  <h1 style={{ display: 'flex', width: '100%', justifyContent: 'center', color: '#142587', padding: '10px' }}>
                    Details of Materials Collected
                  </h1>
                  <div className="consoledivedite">
                    <div className="consoleboxedite">
                      <p className="titleedite">120 LTR BIN</p>
                      <input
                        type="number"
                        value={detail.ltrbin120 || 0}
                        onChange={(e) => handleInputChange(e, 'ltrbin120', index)}
                        className="consoleminiboxedite"
                      />
                    </div>
                    <div className="consoleboxedite">
                      <p className="titleedite">240 LTR BIN</p>
                      <input
                        type="number"
                        value={detail.ltrbin240 || 0}
                        onChange={(e) => handleInputChange(e, 'ltrbin240', index)}
                        className="consoleminiboxedite"
                      />
                    </div>
                    <div className="consoleboxedite">
                      <p className="titleedite">BAG</p>
                      <input
                        type="number"
                        value={detail.bag || 0}
                        onChange={(e) => handleInputChange(e, 'bag', index)}
                        className="consoleminiboxedite"
                      />
                    </div>
                    <div className="consoleboxedite">
                      <p className="titleedite">BOX</p>
                      <input
                        type="number"
                        value={detail.box || 0}
                        onChange={(e) => handleInputChange(e, 'box', index)}
                        className="consoleminiboxedite"
                      />
                    </div>
                    <div className="consoleboxedite">
                      <p className="titleedite">IT EQUIPMENT</p>
                      <input
                        type="number"
                        value={detail.it_equipment || 0}
                        onChange={(e) => handleInputChange(e, 'it_equipment', index)}
                        className="consoleminiboxedite"
                      />
                    </div>
                    <div className="consoleboxedite">
                      <p className="titleedite">Console</p>
                      <input
                        type="number"
                        value={detail.console || 0}
                        onChange={(e) => handleInputChange(e, 'console', index)}
                        className="consoleminiboxedite"
                      />
                    </div>
                  </div>
                </div>

                {/* Form fields for feedback editing... */}

                {/* Update Button */}
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <button className="btn" onClick={() => handleUpdate(index)}>Update Feedback</button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Snackbar for feedback messages */}
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </Box>
    </Modal>
  );
};

export default FeedbackEdite;
