import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  TablePagination,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import dummy from '../asste/userdummy.ico';

const WorkerPage = ({ token }) => {
  const { id } = useParams();
  const [workerData, setWorkerData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(7); // Rows per page
  const location = useLocation();
  const { workerId } = location.state || {};

  const fetchWorkerData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}apiv1/worker/status/${workerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkerData(response.data);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    } finally {
      setLoading(false);
    }
  };

 
  useEffect(() => {
    fetchWorkerData();
  }, [id]);

 

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setPage(0); // Reset page when filter changes
  };

  const downloadReport = async () => {
    const input = document.getElementById('reportContent');
    
    // Check if the input exists
    if (!input) {
        console.error("Element not found");
        return;
    }

    // Load all images in the report content
    const images = input.getElementsByTagName('img');
    const imagePromises = Array.from(images).map(img => {
        return new Promise((resolve) => {
            if (img.complete) {
                resolve(); // Image already loaded
            } else {
                img.onload = resolve; // Resolve when image loads
                img.onerror = resolve; // Resolve on error as well
            }
        });
    });

    // Wait for all images to load
    await Promise.all(imagePromises);

    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();

    // Add content to the PDF
    const imgWidth = 190;
    const pageHeight = pdf.internal.pageSize.height;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
    }

    // Save the PDF
    pdf.save("worker_report.pdf");
};




  if (loading) return <CircularProgress />;

  const filteredStatuses = workerData?.filter(status => {
    const createdAt = new Date(status.createdAt);
    const today = new Date();

    switch (filter) {
      case "today":
        return createdAt.toDateString() === today.toDateString();
      case "last_7_days":
        return (today - createdAt) / (1000 * 60 * 60 * 24) <= 7;
      case "this_month":
        return createdAt.getMonth() === today.getMonth() && createdAt.getFullYear() === today.getFullYear();
      case "last_month":
        return createdAt.getMonth() === today.getMonth() - 1 && createdAt.getFullYear() === today.getFullYear();
      case "this_year":
        return createdAt.getFullYear() === today.getFullYear();
      default:
        return true; // Return all for "all" filter
    }
  }) || [];

  // Prepare data for the chart
  const timeByDate = filteredStatuses.reduce((acc, status) => {
    const date = new Date(status.createdAt).toLocaleDateString(); // Format date
    acc[date] = (acc[date] || 0) + status.time; // Sum time for that date
    return acc;
  }, {});

  const chartCategories = Object.keys(timeByDate);
  const chartData = Object.values(timeByDate); // Use numeric values for the chart

  const totalTime = filteredStatuses.reduce((sum, status) => sum + status.time, 0);
  const totalJobs = filteredStatuses.length;
  const totalHours = Math.floor(totalTime / 60);
  const remainingMinutes = totalTime % 60;
  const sortedStatus = filteredStatuses.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // Slice sortedStatus for pagination
  const paginatedData = sortedStatus.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ p: 2, bgcolor: '#f5f5f5' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
        Driver Report
      </Typography>

      <Button variant="contained" color="primary" onClick={downloadReport} sx={{ mb: 2 }}>
        Download Report
      </Button>

      <Paper sx={{ mt: 2, p: 2, borderRadius: 2, boxShadow: 3 }} id="reportContent">
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <img
                src={ workerData ? `${process.env.REACT_APP_API_URL}images/${workerData[0].worker.profilepic}` : ''}
                alt="Worker Profile"
                style={{
                  borderRadius: '50%',
                  width: '100px',
                  height: '100px',
                  marginRight: '16px',
                  border: '2px solid #888',
                }}
              />
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h6" sx={{ color: '#555' }}>
                  {workerData ? workerData[0].worker.username : ''}
                </Typography>
                <Typography variant="body1" sx={{ color: '#777' }}>
                  Email: {workerData ? workerData[0].worker.email : ''}
                </Typography>
                {/* <Typography variant="body1" sx={{ color: '#777' }}>
                  Vehicle: { workerData[0].worker.vehicles[0] ? workerData[0].worker.vehicles[0].vahicle_name : 'N/A'}
                </Typography>
                <Typography variant="body1" sx={{ color: '#777' }}>
                  Registration No: {workerData[0].worker.vehicles[0] ? workerData[0].worker.vehicles[0].reg_no : 'N/A'}
                </Typography> */}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Select
              value={filter}
              onChange={handleFilterChange}
              variant="outlined"
              sx={{ mb: 2, width: "100%", bgcolor: '#fff', fontSize: '0.875rem' }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="last_7_days">Last 7 Days</MenuItem>
              <MenuItem value="this_month">This Month</MenuItem>
              <MenuItem value="last_month">Last Month</MenuItem>
              <MenuItem value="this_year">This Year</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ color: '#555', mb: 1 }}>Performance Overview</Typography>
        <Typography variant="h6">Total Time: {totalTime < 60 
          ? `${totalTime} minute${totalTime !== 1 ? 's' : ''}` 
          : `${totalHours}.${remainingMinutes < 10 ? '0' : ''}${remainingMinutes} hours`}
        </Typography>
        <Typography variant="h6">Total Jobs: {totalJobs}</Typography>
        <Box sx={{ mt: 2 }}>
          <ReactApexChart
            options={{
              chart: {
                height: 350,
                type: 'bar',
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  dataLabels: {
                    position: 'top',
                  },
                  columnWidth: '40%',
                  barHeight: '80%',
                }
              },
              dataLabels: {
                enabled: true,
                formatter: function (val) {
                  const totalMinutes = val; // Numeric value
                  const totalHours = Math.floor(totalMinutes / 60);
                  const remainingMinutes = totalMinutes % 60;
                  return totalHours > 0 
                    ? `${totalHours}.${remainingMinutes < 10 ? '0' : ''}${remainingMinutes} hours` 
                    : `${totalMinutes} minute${totalMinutes !== 1 ? 's' : ''}`;
                },
                offsetY: -20,
                style: {
                  fontSize: '12px',
                  colors: ["#304758"]
                }
              },
              xaxis: {
                categories: chartCategories,
                title: {
                  text: 'Date',
                },
                labels: {
                  style: {
                    colors: '#666',
                  }
                },
              },
              yaxis: {
                title: {
                  text: 'Total Time',
                },
                labels: {
                  formatter: (val) => {
                    const totalMinutes = val;
                    const totalHours = Math.floor(totalMinutes / 60);
                    const remainingMinutes = totalMinutes % 60;
                    return totalHours > 0 
                      ? `${totalHours}.${remainingMinutes < 10 ? '0' : ''}${remainingMinutes} hours` 
                      : `${totalMinutes} minute${totalMinutes !== 1 ? 's' : ''}`;
                  },
                },
              },
              title: {
                text: 'Time Performance Chart',
                align: 'center',
                style: {
                  fontSize: '16px',
                  fontWeight: 'bold',
                }
              }
            }}
            series={[{
              name: 'Total Time',
              data: chartData
            }]}
            type="bar"
            height={350}
          />
        </Box>

        <TableContainer component={Paper} sx={{ mt: 2, borderRadius: 2, boxShadow: 3, overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Client Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Created At</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Assigned At</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Time</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Complete At</TableCell>
                {/* <TableCell sx={{ fontWeight: 'bold' }}>Vehicle Name</TableCell> */}
                {/* <TableCell sx={{ fontWeight: 'bold' }}>Reg No</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((status) => (
                <TableRow key={status.id}>
                  <TableCell>{status.job.client_name}</TableCell>
                  <TableCell>{status.job.location}</TableCell>
                  <TableCell>{new Date(status.job.createdAt).toLocaleString()}</TableCell>
                  <TableCell>{new Date(status.job.jobAssigned.assigned_date).toLocaleString()}</TableCell>
                  <TableCell>
                    {status.time < 60 
                      ? `${status.time} minute${status.time !== 1 ? 's' : ''}` 
                      : `${Math.floor(status.time / 60)}.${(status.time % 60).toString().padStart(2, '0')} hour${Math.floor(status.time / 60) > 1 ? 's' : ''}`}
                  </TableCell>
                  <TableCell>{new Date(status.createdAt).toLocaleString()}</TableCell>
                  {/* <TableCell>{status.vehicle ? status.vehicle.vahicle_name : ''}</TableCell> */}
                  {/* <TableCell>{status.vehicle ? status.vehicle : ''}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[7, 14, 21]} // Options for rows per page
          component="div"
          count={sortedStatus.length} // Total number of rows
          rowsPerPage={rowsPerPage} // Rows per page
          page={page} // Current page
          onPageChange={(event, newPage) => setPage(newPage)} // Handle page change
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10)); // Update rows per page
            setPage(0); // Reset page to 0 when rows per page changes
          }} // Handle change in rows per page
        />
      </Paper>
    </Box>
  );
};

export default WorkerPage;
