import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  TablePagination
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import dummy from '../asste/userdummy.ico'


const Workers = ({ token }) => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobStatuses, setJobStatuses] = useState([]);
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [newWorker, setNewWorker] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [otp, setOtp] = useState('');
  const [otpToken, setOtpToken] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [workerImages, setWorkerImages] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0); // Reset to first page
  };
  
const nav = useNavigate()
  const fetchWorkers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/workers/get/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWorkers(response.data);
      setFilteredWorkers(response.data);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching workers:", error);
      setLoading(false);
    }
  };

  const fetchJobStatuses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}apiv1/job-statuses`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJobStatuses(response.data);
    } catch (error) {
      // console.error("Error fetching job statuses:", error);
    }
  };

  useEffect(() => {
    fetchWorkers();
    fetchJobStatuses();
  }, [token]);


  useEffect(() => {
    const fetchWorkerImages = async () => {
      const imagePromises = workers.map(async (worker) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}images/${worker.profilepic}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          // if (!response.ok) {
          //   throw new Error("Failed to fetch image");
          // }
    
          const blob = await response.blob();
          return { id: worker.id, url: blob ? URL.createObjectURL(blob) : dummy }; // Use dummy if blob is not available
        } catch (error) {
          // console.error("Error fetching worker image:", error);
          return { id: worker.id, url: dummy }; // Fallback to dummy image
        }
      });
    
      const images = await Promise.all(imagePromises);
      const imageMap = images.reduce((acc, { id, url }) => {
        acc[id] = url;
        return acc;
      }, {});
    
      setWorkerImages(imageMap);
    };
    

    
          fetchWorkerImages();
      

      // Cleanup URLs on unmount
      return () => {
          Object.values(workerImages).forEach(url => URL.revokeObjectURL(url));
      };
  }, [workers,  token]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setFilteredWorkers(workers.filter(worker =>
      worker.username.toLowerCase().includes(query) ||
      worker.email.toLowerCase().includes(query)
    ));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewWorker({ username: '', email: '', password: '', confirmPassword: '' });
    setOtp('');
    setOtpToken('');
  };

  const handleOpenOtpModal = (token) => {
    setOtpToken(token);
    setOpenOtpModal(true);
  };

  const handleCloseOtpModal = () => {
  
    setOtp('');
  };

  const handleAddWorker = async () => {
    // Check if all fields are filled
    if (!newWorker.username || !newWorker.email || !newWorker.password || !newWorker.confirmPassword) {
        setSnackbarMessage("All fields are required.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
    }

    // Check if the email is a valid Gmail address
    const gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    if (!gmailRegex.test(newWorker.email)) {
        setSnackbarMessage("Please enter a valid Gmail address.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
    }

    // Check if passwords match
    if (newWorker.password !== newWorker.confirmPassword) {
        setSnackbarMessage("Passwords do not match.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}apiv1/worker/resignation`, {
            username: newWorker.username,
            email: newWorker.email,
            password: newWorker.password
        },{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        handleOpenOtpModal(response.data.token);
        fetchWorkers();
        setOpenModal(false);
    } catch (error) {
        console.error("Error adding worker:", error);
        setSnackbarMessage("Error adding worker.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setOpenModal(false);
    }
};




 
  const handleChange = (e) => {
    setNewWorker({ ...newWorker, [e.target.name]: e.target.value });
  };

  // Calculate total time and total jobs for each worker
  const calculateTotals = (workerId) => {
    const workerJobs = jobStatuses.filter(job => job.worker_id === workerId);
    const totalTime = workerJobs.reduce((sum, job) => sum + job.time, 0);
    const totalJobs = workerJobs.length;
    return { totalTime, totalJobs };


  };

  const sorted = filteredWorkers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
let showdata= sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)


  return (
    <Box p={2} sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
       Driver
      </Typography>
      <TextField
        label="Search Workers"
        variant="outlined"
        fullWidth
        onChange={handleSearch}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary" sx={{
        background: "#0079ae"
      }} onClick={handleOpenModal}>
        Add  Driver
      </Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
       
              <TableCell>Username</TableCell>
              <TableCell>Total Time</TableCell>
              <TableCell>Total Jobs</TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
     


<TableBody>
  {loading ? (
    <TableRow>
      <TableCell colSpan={3}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  ) : (
    showdata.map((worker) => {
      const { totalTime, totalJobs } = calculateTotals(worker.id);
      return (
        <TableRow  key={worker.id}>
         
          <TableCell >   <div style={{
            display:"flex" ,alignItems:'center'
          }}>{workerImages[worker.id] && (

          
            <img
                src={` ${process.env.REACT_APP_API_URL}images/${worker.profilepic}` || dummy}
                width={25}
                height={25}
                alt={worker.username}
                style={{
                    marginRight: '8px',
                    borderRadius: '50%', // Makes the image round
                    objectFit: 'cover', // Ensures the image covers the circle
                }}
            />
        )}
        <span style={{ fontWeight: 'bold' }}>{worker.username}</span></div></TableCell>
          <TableCell>Total Time: {totalTime < 60 
    ? `${totalTime} minute${totalTime > 1 ? 's' : ''}` 
    : `${Math.floor(totalTime / 60)}.${((totalTime % 60) / 60 * 100).toFixed(0)} hour${Math.floor(totalTime / 60) > 1 ? 's' : ''}`}</TableCell>
          <TableCell>{totalJobs || 0}</TableCell>
          <TableCell><Button  onClick={()=>{
          nav('/workerpage', { state: { workerId: worker.id } });
        }}>View Report</Button></TableCell>
        </TableRow>
      );
    })
  )}
</TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredWorkers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
            borderTop: '1px solid #e0e0e0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            '& .MuiTablePagination-toolbar': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .MuiButtonBase-root': {
                borderRadius: '4px',
                color: '#0079ae',
                '&:hover': {
                    backgroundColor: '#e0f7fa',
                },
            },
            '& .MuiInputBase-input': {
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                '&:focus': {
                    borderColor: '#0079ae',
                },
            },
        }}
    />
      {/* Modal for Adding Worker */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          <Typography variant="h6">Add New  Driver</Typography>
          <TextField
            label="Username"
            name="username"
            value={newWorker.username}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            name="email"
            value={newWorker.email}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={newWorker.password}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={newWorker.confirmPassword}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            onClick={handleAddWorker}
            variant="contained"
            color="primary"
          >
            Add Driver
          </Button>
        </DialogActions>
      </Dialog>
      {/* OTP Modal */}
      {/* <Dialog open={openOtpModal} onClose={handleCloseOtpModal}>
        <DialogContent>
          <Typography variant="h6">Enter OTP</Typography>
          <TextField
            label="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOtpModal}>Cancel</Button>
          <Button
            onClick={handleVerifyOtp}
            variant="contained"
            color="primary"
          >
            Verify OTP
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Workers;
