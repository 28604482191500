import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Snackbar, Alert ,CircularProgress} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../asste/lolo.png";
import "./Landingpage.css";

const Landing_page = ({ fetchToken, token, fetchroll, roll }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Check for token on page load
  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    if (savedToken) {
      fetchToken(savedToken);
      navigate("/dashboard"); // Navigate to dashboard if token exists
    } else {
      setIsLoading(false); // If no token, set loading to false and show the login form
    }
  }, [fetchToken, navigate]);

  const handeluserlogin = async () => {
    if (!email || !password) {
      setError("Email and Password are required");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}apiv1/admin/login`,
        {
          email,
          password,
        }
      );

      const userToken = response.data.token;
      const userRoll = response.data.roll;

      // Store token in localStorage
      localStorage.setItem("token", userToken);

      // Call functions to update state
      fetchToken(userToken);
      fetchroll(userRoll);

      // Navigate to dashboard
      navigate("/dashboard");
    } catch (error) {
      setError("Invalid Email or Password");
    }
  };



  if (isLoading) {
    // Show a loader while token validation is in progress
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }


  return (
    <div className="landingpage">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          background: "url(/path-to-your-background-image.jpg) no-repeat center center fixed",
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            width: 350,
            padding: 4,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <img src={logo} alt="Logo" width="100%" />
          <Typography align="center" sx={{ marginY: 2 }}>
            Admin Login
          </Typography>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2, backgroundColor: "#0079ae" }}
            onClick={handeluserlogin}
          >
            Sign In
          </Button>
        </Box>
      </Box>
      {error && (
        <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default Landing_page;
