// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landingpage{
    width: 100%;
    /* background-image: url('../assets/background.png'); */

    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter Tight', sans-serif;
}
.home{
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: 'Inter Tight', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Landingpage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uDAAuD;;IAEvD,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sCAAsC;AAC1C;AACA;IACI,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,sCAAsC;AAC1C","sourcesContent":[".landingpage{\r\n    width: 100%;\r\n    /* background-image: url('../assets/background.png'); */\r\n\r\n    background-size: cover;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-family: 'Inter Tight', sans-serif;\r\n}\r\n.home{\r\n    width: 100%;\r\n    background-size: cover;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n    font-family: 'Inter Tight', sans-serif;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
